import React from 'react';

interface IconProps {
  size?: number;
}

export const IconVerified: React.FC<IconProps> = ({ size = 16 }) => {
    return (
      <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 1.33334C4.31337 1.33334 1.33334 4.31337 1.33334 8C1.33334 11.6866 4.31337 14.6667 8 14.6667C11.6866 14.6667 14.6667 11.6866 14.6667 8C14.6667 4.31337 11.6866 1.33334 8 1.33334ZM11.2258 6.61751L7.27804 10.5499C7.15515 10.6728 7.00154 10.7343 6.84793 10.7343C6.69432 10.7343 6.52535 10.6728 6.41782 10.5499L4.46698 8.61444C4.2212 8.36867 4.2212 7.98464 4.46698 7.73887C4.71275 7.49309 5.09678 7.49309 5.34255 7.73887L6.84793 9.24424L10.3502 5.74194C10.596 5.49616 10.98 5.49616 11.2258 5.74194C11.4562 5.98771 11.4562 6.3871 11.2258 6.61751Z" fill="#8F4DF4"/>
      </svg>
    )
}
